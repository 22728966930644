#header {
  background-color: var(--sebrae-white);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
      position: relative;
      z-index: 3;

      &.active {
        background: var(--sebrae-white);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .list-menu {
      position: fixed;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      left: -100%;
      transition: 0.3s;

      &.open {
        background: rgba(0, 0, 0, 0.3);
        left: 0;
      }

      .content {
        background-color: var(--sebrae-blue);
        width: 70%;
        height: 100%;
        padding: 15px 15px 15px 68px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .close {
          position: absolute;
          width: 30%;
          height: 100%;
          top: 0;
          right: 0;
        }

        .title-menu {
          a {
            text-decoration: none;
          }
          h3 {
            color: var(--sebrae-white);
            font-size: 19px;
            line-height: normal;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        ul {
          padding-left: 12px;
          padding-top: 23px;

          li {
            position: relative;
            height: 40px;
            display: flex;
            align-items: center;

            &.active {
              &:before {
                content: "";
                width: 4px;
                height: 100%;
                position: absolute;
                left: -11px;
                top: 0;
                background-color: var(--sebrae-white);
              }

              a {
                font-weight: 400;
              }
            }

            a {
              text-decoration: none;
              color: var(--sebrae-white);
              font-size: 16px;
              font-style: normal;
              font-weight: 300;
              line-height: 23.3px;
            }

            h4,
            a,
            button {
              font-size: 16px;
              color: var(--sebrae-white);
              font-weight: 300;

              span {
                display: block;
                &.mid {
                  font-size: 12px;
                  font-weight: 300;
                }
              }

              svg {
                display: none;
              }
            }
          }
        }

        .profile-menu {
          position: relative;

          .icon-profile {
            background-color: var(--sebrae-white);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: -45px;
            top: 0;
          }
          ul {
            padding-top: 0;

            li {
              a {
                color: var(--sebrae-white);
                font-weight: 300;
              }
            }
          }
        }
      }
    }
  }

  .logo {
    width: 40px;
  }

  @media (min-width: 560px) {
    .svg-color {
      svg {
        transition: 0.3s;
        path {
          fill: var(--sebrae-blue);
        }
      }
    }
    width: 70px;

    nav {
      justify-content: left;
      align-items: start;
      flex-direction: column;
      gap: 20px;
      padding-top: 30px;

      .profile,
      .logo {
        position: relative;
        z-index: 2;
      }

      .title-menu,
      .btn-menu {
        display: none;
      }

      .list-menu {
        width: 70px;
        left: 0;

        .content {
          width: 70px;
          background: linear-gradient(45deg, #9285f9 0%, #cd5be8 100%);
          padding: 110px 0 0;

          .close {
            display: none;
          }

          ul {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            li {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              justify-content: center;
              align-items: center;
              transition: 0.3s;
              margin: 15px 0;

              &.disabled {
                &:not(.active) {
                  opacity: 0;
                  display: none;
                }
              }

              a {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              &.active {
                background-color: white;
                @extend .svg-color;

                &:before {
                  content: none;
                }

                h4 {
                  font-weight: 400;
                }
              }

              &:hover {
                background-color: white;
                @extend .svg-color;
              }

              a {
                display: flex;

                span {
                  display: none;
                }

                svg {
                  display: block;
                }
              }
            }
          }

          .profile-menu {
            .icon-profile {
              position: relative;
              left: 4px;
              margin: 10px 10px 15px;
              cursor: pointer;
            }
            ul {
              background-color: #ffffff;
              position: absolute;
              left: 74px;
              bottom: -300px;
              width: 300px;
              border-radius: 8px;
              box-shadow: 0 2px 28px 0 rgba(20, 46, 82, 0.2);
              padding: 15px 0;
              transition: 0.4s;

              &.profile-menu-open {
                bottom: 15px;
              }

              &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: -32px;
                right: auto;
                border-bottom: 20px solid transparent;
                border-top: 20px solid transparent;
                border-right: 20px solid #ffffff;
                border-left: 20px solid transparent;
              }

              li {
                width: 100%;
                justify-content: start;
                border-radius: 0;
                margin: 0;
                height: 40px;
                padding: 0 20px;
                transition: 0.3s;

                &:hover {
                  background: #f3f3f3;
                }

                &:first-child {
                  margin-bottom: 10px;
                  &:hover {
                    background-color: transparent;
                  }
                }

                a {
                  display: block;
                  width: 100%;
                  height: 100%;
                  padding: 8px 0;
                  color: var(--sebrae-black);
                }

                h4,
                a {
                  display: flex;
                  color: var(--sebrae-black);
                  flex-direction: column;
                  align-items: start;

                  span {
                    display: block;
                  }

                  img {
                    display: none;
                  }
                }
                button {
                  color: var(--sebrae-black);
                  width: 100%;
                  height: 100%;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
}
