.planning {
  $colors: (
    "1": #994599,
    "2": #5c8fd6,
    "3": #005eb8,
    "4": #0f438a,
    "5": #0024a9,
    "6": #142e52,
    "7": #0a1729,
    "8": #14adcc,
    "9": #0e778b,
    "10": #094f5d,
  );

  .steps {
    width: 100%;
    min-height: calc(100vh - 56px);
    padding-bottom: 70px;

    &__header {
      ul {
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          width: 100%;
          height: 8px;
          display: flex;
          align-items: center;
          overflow: hidden;
          transition: all 0.2s ease-in-out;
          border: 1px solid #b6bfc8;
          background-color: var(--sebrae-purple);
          color: var(--sebrae-white);
          padding: 0 16px;

          @each $idx, $color in $colors {
            &:nth-child(#{$idx}) {
              background-color: $color;
              border-color: $color;
            }
          }

          p {
            font: {
              family: var(--font-lato);
              size: 13px;
              weight: 700;
            }
            visibility: hidden;
          }

          &.has-name {
            padding: 8px 16px;
            height: 32px;

            p {
              visibility: visible;
            }
          }
          &.active {
            padding: 8px 16px;
            height: 55px;

            p {
              font-size: 17px;
              visibility: visible;
            }
          }
        }
      }

      @media screen and (max-width: 767px) {
        &.expanded {
          ul {
            li {
              height: 33px;

              p {
                font-size: 13px;
                visibility: visible;
              }
            }
          }
        }
      }
    }
    &__body {
      padding: 16px 0;
    }

    .step {
      h5 {
        margin: 16px 0;
      }
    }
    .total-investment {
      .BaSe--input {
        .BaSe--input-wrapper {
          width: 50%;
        }
      }
    }
    .receipt-deadline,
    .payment-terms {
      .installments {
        margin: 15px 0;

        .custom-input-range {
          margin: 0;
        }
      }
      .btn-add-more {
        width: 24px;
        display: block;
        margin: 24px auto;
      }
      .wrapper-button-secondary {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px auto;

        button {
          border-color: var(--sebrae-white);
          font-size: 13px;

          span {
            font-size: inherit;
          }
        }
      }
    }
    .inventory-turnover {
      .btn-add-more {
        width: 24px;
        display: block;
        margin: 10px auto 16px;
      }
      .grid-buttons-area {
        div {
          align-items: center;
        }

        .top-input {
          padding-left: 4px;

          @media (max-width: 320px) {
            padding-left: 8px;
          }
        }

        .remove-button {
          padding-top: 17px;
        }
      }
      form {
        max-width: 389px;
      }
      .result {
        p {
          color: var(--sebrae-blue);
          font: {
            size: 11px;
            weight: 400;
          }
          margin: 0;
        }

        input {
          margin-right: 6px;

          @media (max-width: 380px) {
            margin-right: 10px;
          }

          @media (max-width: 320px) {
            margin-right: 13px;
          }
        }
        .input-100 {
          .BaSe--input {
            margin: 0;

            .BaSe--input-wrapper {
              border-radius: 4px;
              overflow: hidden;
            }
            input {
              background: var(--sebrae-white);
              border-radius: 4px;
              border-width: 2px;
              border-image-slice: 1;
              border-image-source: linear-gradient(
                to right,
                #3b4aff 0%,
                #40bbff 100%
              );
            }
          }
        }
        .input-not-100 {
          .BaSe--input {
            margin: 0;

            .BaSe--input-wrapper {
              border-radius: 4px;
              overflow: hidden;
            }

            input {
              background: var(--sebrae-white);
              border-radius: 4px;
              border-width: 2px;
              border-image-slice: 1;
              border-image-source: linear-gradient(
                to right,
                #ff3b3b 0%,
                #760303 100%
              );
            }
          }
        }
        .BaSe--grid-item:first-of-type {
          align-self: end;
        }
      }
    }
    .guarantees {
      p {
        font: {
          size: 13px;
          weight: 400;
        }
        color: #181a1b;
        margin: 15px 0;
        max-width: 493px;
      }
      form {
        max-width: 596px;

        .BaSe--grid,
        .wrapper-select {
          max-width: 252px;
          margin: 0;
        }
      }
      .group-input {
        padding-bottom: 15px;
        margin-bottom: 15px;

        .BaSe--input {
          margin-bottom: 0;
        }
      }
      .box-fampe {
        width: 100%;
        max-width: 252px;
        border-radius: 8px;
        border: 1px solid var(--sebrae-light-gray-2);
        background: var(--sebrae-white);
        box-shadow: 0 4px 8px 0 rgba(20, 46, 82, 0.2);
        padding: 16px;
        color: var(--sebrae-blue);

        p {
          font-size: 16px;
          margin: 0 0 16px;
          color: inherit;
        }
        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      @media screen and (min-width: 768px) {
        .planning__footer {
          justify-content: baseline;
        }
        .BaSe--grid {
          position: static;
        }
      }
      @media screen and (min-width: 1280px) {
        form {
          width: 100%;

          .BaSe--grid {
            max-width: 100%;
          }
          .wrapper-select,
          .BaSe--input {
            max-width: 286px;
          }
        }
        .box-fampe {
          max-width: 286px;
          margin: 0 0 0 auto;
        }
      }
    }
    .results {
      max-width: 612px;

      .notes-modal {
        span {
          font-weight: bold;
        }
      }
      .results-information {
        border-radius: 8px;
        padding-bottom: 8px;
        background-color: #fff;
        border: 1px solid #e0e5eb;
        box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.08);

        &__data {
          padding-left: 16px;
          display: flex;
          flex-wrap: wrap;
          align-items: stretch;
          // align-items: center;
          justify-content: space-between;
          min-height: 56px;
          border-bottom: 1px solid #e0e5eb;
          margin-bottom: 0 !important;
        }

        &__info {
          width: 50%;
          display: flex;
          align-items: center;
          word-wrap: break-word;
        }

        &__value {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;

          &.highlighted {
            min-height: 56px;
            background-color: #cfe2fc;
            padding: 4px 8px;
          }
        }
      }
      .message-observation {
        text-align: end;

        p {
          padding-top: 5px;
        }
      }
      .second-tip-block {
        h6 {
          font-size: 20px;
          font-weight: 900;
        }
      }

      .bar {
        border-bottom: 1px solid #e0e5eb;
        margin: 24px 0;
      }
      .title {
        padding: 24px 0 8px;

        &.square-title {
          padding: 0;
          padding-bottom: 16px;
        }
      }
      .form {
        .projected-flow {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          padding: 16px;
          background-color: #fff;
          border: 1px dashed #40bbff;
          border-radius: 4px;
          margin-top: 2px;

          p {
            font-size: 13px;
            padding-bottom: 16px;
          }
        }

        .goal {
          padding: 16px;
          display: flex;
          justify-content: space-between;
          background-color: #fff;
          gap: 16.3px;

          &__input-area {
            .date-picker {
              div[width="16"] {
                z-index: 2;

                & > div {
                  z-index: 1;
                }
              }
            }
          }

          img {
            align-self: flex-start;
            padding-top: 2px;
          }

          p {
            padding-bottom: 16px;
          }
        }

        .chart-area {
          .wrapper-select {
            display: flex;
            align-items: center;
            gap: 15px;

            .btn-clear {
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 23.3px;
              background-color: var(--sebrae-blue);
              color: var(--sebrae-white);
              padding: 5px 10px;
              border-radius: 5px;
              margin-top: 12px;
              width: 100px;
            }
          }
          &__data {
            padding: 30px 0;
          }

          div[width="286"] {
            z-index: 2;
          }

          div {
            input {
              width: 100%;
            }
          }
        }

        .submit-and-download {
          display: flex;
          justify-content: center;
          padding: 24px 20px;
        }

        .technical-notes {
          display: flex;
          cursor: pointer;
          max-width: 103px;
          text-decoration: underline;

          &:hover {
            color: #000;
            p {
              color: #000;
            }
          }
        }

        textarea {
          min-height: 148px;
        }
      }
      .finish-and-download {
        display: flex;
      }

      @media screen and (min-width: 768px) {
        padding-bottom: 40px;

        .form {
          .submit-and-download {
            display: none;
          }

          .technical-notes {
            padding: 24px 0;
          }
        }
      }
      @media screen and (min-width: 992px) {
        .results-information {
          padding-bottom: 0;

          &__info {
            width: 70%;
          }

          &__value {
            width: 30%;
            text-align: center;
          }
        }

        .form {
          .goal {
            justify-content: flex-start;
          }
        }
      }
      @media screen and (min-width: 1024px) {
        .form {
          .chart-area {
            &__data {
              padding: 30px 0px;
            }
          }
        }
      }
    }
    .cash-flow {
      padding-bottom: 30px;

      .wrapper-flux {
        min-width: 250px;

        .default {
          padding: 16px;
          display: flex;
          align-items: center;
          box-shadow: 0 1px 4px 0 rgba(20, 46, 82, 0.2);
          margin-bottom: 16px;
          justify-content: space-between;
          font-size: 15px;
        }
        .date {
          border-radius: 4px;
          border: 1px solid var(--sebrae-light-gray-2);
          background: #e2e6e8;
          height: 40px;
          color: #243342;
          font-style: normal;
          font-weight: 400;
          line-height: 23.3px;
          @extend .default;

          &.not-menu {
            background-color: transparent;
            box-shadow: none;
            border: 0;
            opacity: 0;
          }
        }
        h3 {
          border-radius: 12px;
          background: linear-gradient(45deg, #9285f9 0%, #cd5be8 100%);
          box-shadow: 0 1px 4px 0 rgba(20, 46, 82, 0.2);
          height: 48px;
          color: var(--sebrae-white);
          font-style: normal;
          font-weight: 700;
          line-height: 23.3px;
          @extend .default;

          &.gradient {
            background: var(--sebrae-white);
            color: #243342;
          }
        }
        .exits {
          .card-flux {
            &:first-of-type {
              padding: 10px 15px;
              min-height: 48px;
            }
          }
          .empty {
            background: transparent;
            box-shadow: none;
            p {
              visibility: hidden;
            }
          }
          .content {
            width: 100%;
          }
        }
        .wrapper-card {
          .card-flux {
            margin-bottom: 0;
            padding: 10px 15px;

            &:first-child {
              margin-bottom: 14px;
            }
            &:last-child {
              margin-bottom: 15px;
            }
            p {
              padding: 0;
            }
          }
        }
        .card-flux {
          border-radius: 12px;
          background: var(--sebrae-white);
          color: #243342;
          font-style: normal;
          font-weight: 700;
          line-height: 23.3px;
          width: 100%;
          align-items: center;
          min-height: 56px;
          @extend .default;

          &-gray {
            background: var(--sebrae-light-gray-2);
            color: #8b99a7;

            &.active {
              background-color: #cfe2fc;
              color: #005eb8;
            }
          }

          .title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            min-height: 35px;
            align-items: center;
            margin-bottom: 18px;

            &-values {
              width: 100%;
            }
          }
          .title-exits {
            width: 100%;
            display: flex;
            justify-content: space-between;

            p {
              margin: 0;
            }

            &:last-of-type {
              p {
                padding: 0;
              }
            }
          }

          p {
            color: #617385;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 23.3px;
            padding-bottom: 20px;
            display: flex;
            justify-content: space-between;
            width: 100%;

            &:last-child {
              padding: 0;
            }
          }

          .arrow {
            border: 1px solid #617385;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.small {
            padding: 8px 15px;
            min-height: 40px;
            margin-bottom: 0 !important;
          }
          &.balance {
            flex-direction: column;

            .header {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            .has-balance {
              width: 100%;
              margin-top: 5px;
            }
            p {
              padding: 0;
              margin: 0 0 2px;
              font: {
                size: 13px;
              }
              line-height: 1.3;
              color: #617385;
            }

            &.has-balance {
              min-height: 100px;
            }
            &.small {
              min-height: 56px;
              margin-bottom: 60px !important;
              justify-content: center;
              align-items: start;
            }
          }
        }
        &.title {
          min-width: 100%;
        }
        &.dynamic {
          .exits {
            .card-flux {
              margin-bottom: 8px;
            }
          }
        }
      }

      .SettingsSliderCashFlow {
        &.slick-slider {
          margin: 0 -16px !important;
        }
        .slick-track {
          display: flex !important;
        }
        .slick-slide {
          height: inherit !important;

          div:first-child {
            height: 100%;
          }
        }
        .slick {
          &-list {
            .wrapper-flux {
              padding-left: 15px;
            }
          }

          &-dots {
            position: relative;
            bottom: 0;

            li {
              width: 18px;
              height: 10px;
              button {
                background-color: #005eb8;
                border-radius: 50%;
                opacity: 1;
                width: 10px;
                height: 10px;
                transition: 0.3s;
                font-size: 1px;

                &:before {
                  content: none;
                }
              }
              &.slick-active {
                button {
                  border-radius: 66.667px;
                  width: 18px;
                }
              }
            }
          }

          @media (min-width: 767px) {
            &-list {
              overflow: visible;
            }
          }
        }
        .wrapper-flux {
          position: relative;
          height: 100%;
          padding-bottom: 72px;

          .capacity {
            position: absolute;
            bottom: 16px;
            left: 0;
            right: 0;
            width: calc(100% - 15px);
            margin: 0 0 0 auto;
            padding: 10px 16px;
          }
        }
        &.empty {
          .wrapper-flux {
            padding-left: 0;

            .capacity {
              width: 100%;
            }
          }
        }
      }

      .label-installments {
        padding: 10px 0;
        border-top: 1px solid #e0e5eb;
        margin-top: 16px;
      }

      .form {
        &-date {
          .description {
            font-weight: 600;
            span {
              color: #243342;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 23.3px;
              display: block;
              padding-top: 16px;
            }
          }
          label {
            color: #617385 !important;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-bottom: 5px;
          }
        }

        &-initial-values {
          .description {
            font-weight: 500;
            padding-bottom: 16px;
          }

          .wrapper-date {
            max-width: 50%;
            margin-bottom: 16px;
          }

          label {
            color: #617385 !important;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-bottom: 5px;
          }
        }
      }

      .date {
        .currency {
          padding: 10px 0 0;
        }

        .wrapper-date {
          display: flex;
          gap: 20px;

          & > div {
            width: 100%;
          }
        }
      }

      .BaSe--checkbox {
        div {
          &:last-child {
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
            width: 18px;
            height: 18px;
          }
        }
      }

      .additional-month {
        max-height: 400px;
        overflow: auto;
        padding-right: 15px;
        margin-right: -10px;
        margin-top: 15px;
      }

      .wrapper-button {
        padding: 20px 0 10px;
        text-align: right;

        button {
          background-color: var(--sebrae-blue);
          color: var(--sebrae-white);
          border-radius: 4px;
          height: 40px;
          padding: 0 15px;
        }
      }

      button {
        &:disabled {
          opacity: 0.7;
          cursor: no-drop;
        }
      }

      @media (min-width: 767px) {
        .wrapper-flux {
          min-width: 286px;
        }
        .additional-month {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 0 16px;
        }
        .wrapper-button {
          text-align: left;
        }
      }
    }
  }
  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 16px;
    width: 100%;
    z-index: 2;
    background-color: var(--sebrae-white-2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;

    &.start {
      justify-content: flex-end;
    }

    @media screen and (min-width: 560px) {
      padding-left: 86px;
    }
  }
  .group-input {
    border-bottom: 1px solid var(--sebrae-light-gray-2);
    &.desktop {
      border: none;
    }

    @media screen and (min-width: 1280px) {
      &.desktop {
        border-bottom: 1px solid var(--sebrae-light-gray-2);
      }
      &.mobile {
        border: none;
      }
    }
  }
  .BaSe--grid {
    gap: 16px;
  }
  .finish-and-download {
    display: none;
    //position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto 8px;
    width: 100%;
    //width: calc(100% - 32px);
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 4px;
    gap: 8px;

    p {
      color: var(--sebrae-white);
      margin: 0;
    }

    &.disabled {
      border: none;
      background: #b6bfc8;
      cursor: not-allowed;
    }
    &.finish {
      border: none;
      background: linear-gradient(45deg, #9285f9 0%, #cd5be8 100%);
    }
    &.download {
      border: 2px solid #e0e5eb;
      background: #f5f7fa;

      p {
        color: #243342;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .steps {
      display: flex;
      min-height: 100vh;
      padding: 0;

      .steps__header {
        position: relative;
        width: 100%;
        max-width: 326px;
        padding: 60px 16px 24px;
        background: var(--sebrae-light-gray-2);

        .content_header {
          position: fixed;
          max-width: 294px;
          width: 100%;
        }

        ul {
          li {
            height: 48px;
            margin-bottom: 8px;
            border-radius: 4px;

            p {
              font-size: 16px;
              visibility: visible;
            }

            @each $idx, $color in $colors {
              &:nth-child(#{$idx}) {
                background-color: $color !important;
                border-color: $color !important;
                color: $color !important;

                &.is-completed {
                  background-color: transparent !important;
                  border-color: $color !important;
                  color: $color !important;
                  cursor: pointer;
                }
                &.active {
                  background-color: $color !important;
                  border-color: $color !important;
                  color: var(--sebrae-white) !important;
                }
              }
            }
            &.has-name {
              height: 48px;
            }
          }
          li:not(.active) {
            background-color: transparent !important;
            border-color: #b6bfc8 !important;
            color: #b6bfc8 !important;
          }
        }
      }
      .steps__body {
        position: relative;
        width: 100%;
        padding: 45px 16px 70px;
        overflow: hidden;
      }
      .step {
        h5 {
          font-size: 23px;
        }
      }
    }
    &__footer {
      position: absolute;
      padding: 16px;
    }
    .finish-and-download {
      display: flex !important;
    }
  }
  @media screen and (min-width: 1366px) {
    .steps {
      .steps__body {
        padding: 45px 100px 24px 16px;
      }
    }
    &__footer {
      padding-right: 116px;
    }
  }
}
