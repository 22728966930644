.box-pill-planning {
  display: flex;
  justify-content: space-between;
  padding: 80px 16px 16px 16px;
  gap: 20px;

  @media (max-width: 350px) {
    padding: 60px 16px 16px 16px;
  }

  @media (min-width: 360px) and (max-width: 559px) {
    padding: 60px 16px 25px 16px;
  }

  @media (min-width: 560px) and (max-width: 992px) {
    padding: 80px 16px 50px 16px;
  }

  @media (min-width: 1280px) {
    padding: 20px 80px 50px 80px;
  }

  @media (min-width: 993px) and (max-width: 1279px) {
    padding: 75px 30px 25px 30px;
  }

  @media (min-width: 1920px) {
    justify-content: space-evenly;
  }

  @media (max-width: 1300px) {
    flex-direction: column;
  }

  .pill-planning-left {
    display: flex;
    flex-direction: column;

    @media (min-width: 1300px) {
      max-width: 720px;
    }

    .h4-pad {
      padding: 24px 0;
    }

    &__container {
      .block-how-to-use {
        @media (max-width: 1300px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &__accordion {
          display: flex;
          justify-content: space-between;
          width: 100%;
          cursor: pointer;
        }

        &__hidden-content {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.5s ease;

          ul {
            padding: 0 15px;
            list-style: decimal;

            li {
              padding: 10px 5px;
            }
          }

          p {
            padding: 5px 0;
          }

          &.open {
            max-height: 500px;
          }
        }

        &__desktop {
          margin-bottom: 16px;

          span {
            cursor: pointer;
            text-decoration: underline;
            font-weight: bold;
          }

          @media (max-width: 992px) {
            display: none;
          }
        }

        button {
          margin-top: 24px;
        }
      }

      .line {
        flex-grow: 1;
        height: 1px;
        background-color: var(--sebrae-light-gray-2);
        margin: 32px 10px;

        @media (min-width: 1301px) {
          display: none;
        }
      }
    }
  }

  .pill-planning-right {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      top: 50px;
      bottom: 0;
      width: 2px;
      background-color: var(--sebrae-light-gray-2);
    }

    @media (max-width: 1319px) {
      &::before {
        left: -15px;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 16px;

      .pills-cards {
        gap: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (max-width: 1300px) {
          a {
            display: flex;
            justify-content: center;
          }
        }

        .video {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 16px;
          max-width: 357px;
          min-height: 207px;
          border-radius: 4.91px 24.54px 24.54px 24.54px;
          background-color: #fff;
          box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.05);
        }

        .image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 357px;
          min-height: 184px;
        }
      }

      .see-all {
        color: #006bff;
        text-decoration: underline;
        display: flex;
        justify-content: center;

        &:hover {
          filter: contrast(5);
        }
      }
    }

    @media (min-width: 1301px) {
      width: 30%;
      padding-top: 52px;

      &__content {
        max-width: 357px;
      }

      &__mobile {
        display: none;
      }
    }

    &__desktop {
      @media (max-width: 1300px) {
        display: none;
      }
    }
  }
}
