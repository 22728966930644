.chart-wrapper {
  .slick-arrow {
    display: none !important;
  }

  .slick-list {
    border-radius: 8px;
    background-color: #ffffff;
    padding-top: 10px !important;

    @media (min-width: 768px) {
      padding: 0;
    }
  }

  .slick-dots {
    li {
      margin: 0 5px;
      height: 0;

      button {
        background-color: #005eb8;
        border-radius: 40%;
        opacity: 1;
        width: 10px;
        height: 10px;
        transition: 0.3s;
        font-size: 1px;

        &:before {
          content: none;
        }
      }

      &.slick-active {
        button {
          background-color: #005eb8;
          width: 18px;
        }
      }
    }
  }

  .chart-slide {
    padding: 10px;

    .chart-bar {
      display: flex;
      justify-content: center;

      canvas {
        max-width: 100%;
        height: auto;
        display: block;
      }
    }
  }
}
