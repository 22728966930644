.box {
  position: relative;
  height: 89.98px;
  width: 100%;
  padding: 0 7.5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 22px;
  background: #fff;

  &::before {
    border-radius: 8px;
    content: "";
    background-image: var(
      --border-color,
      linear-gradient(to top, #84f4bc 0%, #ffb380 100%)
    );
    top: -1.5px;
    left: -1.5px;
    bottom: -1.5px;
    right: -1.5px;
    position: absolute;
    z-index: -1;
  }

  @media (max-width: 320px) {
    gap: 5px;
    padding: 0 7.5px 0 7.5px;
  }

  img {
    margin-bottom: 3px;
    flex-shrink: 0;
  }

  h6 {
    text-align: center;
    flex: 1;
  }
}
