.auth {
  .container {
    padding: 0 24px 90px;
    height: 100%;
    position: relative;
  }
  .presentation {
    padding-top: 30px;

    &__footer {
      position: absolute;
      bottom: 25px;
      width: 100%;
      left: 0;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
    }
  }
  h2 {
    color: var(--sebrae-blue);
    font: {
      family: var(--font-alegreya);
      size: 27px;
      weight: 700;
    }
    line-height: 1.2;
    margin-bottom: 16px;
  }
  h6 {
    background: linear-gradient(45deg, #9285F9 0%, #CD5BE8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font: {
      family: var(--font-lato);
      size: 19px;
      weight: 700;
    }
    line-height: 1.2;
    margin-bottom: 16px;
  }
  p {
    font: {
      family: var(--font-lato);
      size: 16px;
      weight: 400;
    }
    line-height: 23.3px;
  }
  .step {
    figure {
      margin: 16px auto;

      img {
        width: 300px;
        margin: 0 auto;
      }
    }
  }
  .pages-desktop, .navigate-desktop {
    display: none;
  }

  @media screen and (min-width: 375px) {
    .step {
      figure {
        margin-bottom: 37px;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .container {
      max-width: 1300px;
    }
    .steps {
      margin: 0 auto;
      width: 100%;
      max-width: 1024px;
    }
    .step {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 127px;
      flex-direction: row-reverse;

      figure {
        margin: 0;
        img {
          width: auto;
        }
      }
      .text {
        position: relative;
        max-width: 339px;
      }
    }
    h2 {
      font-size: 33px;
    }
    h6 {
      font-size: 23px;
    }
    .presentation {
      padding-top: 12%;

      &__footer {
        display: none;
      }
    }
    .pages-desktop {
      padding: 35px 0 16px;
      display: block;
    }
    .navigate-desktop {
      display: block;
    }
  }
}