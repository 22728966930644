.floating-menu {
  position: absolute;
  width: 300px;
  right: -100%;
  top: 65px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 28px 0 rgba(20, 46, 82, 0.2);
  padding: 15px;
  transition: 0.3s;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    top: -28px;
    right: 6px;
    border-bottom: 20px solid #ffffff;
    border-top: 20px solid transparent;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
  }

  .close {
    position: absolute;
    right: 4px;
    top: 7px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    line-height: 1;
    color: #b6bfc8;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: black;
    }
  }

  ul {
    li {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-top: 15px;
      transition: 0.3s;
      padding: 5px 10px;
      border-radius: 12px;
      cursor: pointer;

      &:hover {
        background: #f3f3f3;
      }

      &:first-child {
        margin-top: 0;
        border-bottom: 1px solid var(--sebrae-light-gray-2);
        padding-bottom: 15px;
        border-radius: 0;

        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }

      .title {
        h4 {
          color: #394d60;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-bottom: 8px;
        }

        p {
          color: #8b99a7;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  @media (min-width: 560px) {
    right: auto;
    left: -400px;
    top: 0;
  }
}

.floating {
  .floating-menu {
    right: 10px;
    top: 65px;
  }

  .btn-circle {
    z-index: 2;
    position: relative;
  }

  @media (min-width: 560px) {
    .floating-menu {
      right: auto;
      left: 58px;
      top: 0;

      &:before {
        top: 0;
        left: -32px;
        right: auto;
        border-bottom: 20px solid transparent;
        border-top: 20px solid transparent;
        border-right: 20px solid #ffffff;
        border-left: 20px solid transparent;
      }
    }
  }
}
