.pdf-container {
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;

  .pdf-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;

    .pdf-img-planejadora-sebrae {
      width: 10px;
    }

    &-title {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .pdf-sub-header {
    display: flex;
    padding-bottom: 8px;
    margin-top: 15px;
    justify-content: center;
    background-color: #ced2d7;

    h4 {
      color: #626467;
    }
  }

  .pdf-title {
    padding: 15px 0 5px;
  }

  .pdf-results-information {
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #e0e5eb;

    &__data {
      padding-left: 16px;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      // align-items: center;
      justify-content: space-between;
      min-height: 46px;
      border-bottom: 1px solid #e0e5eb;
      margin-bottom: 0 !important;
    }

    &__info {
      width: 50%;
      display: flex;
      align-items: center;
      word-wrap: break-word;
    }

    &__value {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      &.highlighted {
        min-height: 46px;
        background-color: #cfe2fc;
        padding: 4px 8px;
      }
    }
  }

  .pdf-inputs {
    padding-top: 10px;

    .pdf-text-area {
      padding: 8px;
      border-radius: 4px;
      border: 1px solid rgb(182, 191, 200);
      background-color: rgb(245, 247, 250);
      min-height: 80px;
      margin: 4px 0 12px;

      h6 {
        white-space: pre-wrap;
        color: rgb(40, 42, 44);
      }
    }

    .pdf-text-inputs {
      @extend .pdf-text-area;

      padding-top: 2px;
      min-height: 35px;
    }
  }

  .pdf-output-data-header {
    display: flex;
    gap: 30;
    font-weight: bold;
    margin-bottom: 10px;

    p,
    div {
      flex: 1;
    }
  }

  .pdf-output-data {
    display: flex;
    gap: 30;

    h3,
    p {
      flex: 1;
    }
  }

  .pdf-results-observation {
    margin-top: 15px;
    padding: 10px 16px;
    border-radius: 20px;
    color: #626467;
    border: 1px solid #b6bfc8;

    line-height: 20px;
    ul {
      li {
        padding-left: 16px;
      }
    }
  }

  .pdf-message-observation {
    text-align: end;

    p {
      padding-top: 5px;
    }
  }
}
