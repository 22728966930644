.company__radio {
  height: 76px;
  max-width: 343px;
  width: 100%;
  margin: 6px 0;
  border-radius: 8px;
  border: 1px solid #b6bfc8;
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);

  @media (max-width: 350px) {
    margin: 4px 0;
  }

  &__checkbox-comp {
    .BaSe--checkbox {
      div {
        margin-top: 3px;

        &:last-child {
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  &__data {
    display: flex;
    padding-left: 25px;
    align-items: center;
    gap: 11.6px;

    &__text {
      display: flex;
      flex-direction: column;

      .company-name {
        max-width: 25ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (max-width: 768px) {
          max-width: 18ch;
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
    border: 3px solid #005eb8;
  }

  &.selected {
    border: 3px solid #005eb8;
  }
}
