.dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  backdrop-filter: blur(4px);
  
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--sebrae-gray);
    opacity: .5;
    display: block;
  }
  &__content {
    position: relative;
    z-index: 1;
    width: 100%;
    min-height: 130px;
    border-radius: 4px;
    border: 2px solid #CFE2FC;
    background-color: var(--sebrae-white);
    color: var(--sebrae-gray);
    box-shadow: 0 32px 30px 0 rgba(20, 46, 82, 0.20);
  }
  &__header {
    position: relative;
    width: 100%;
    min-height: 40px;
    padding: 14px 30px 14px 16px;
    border-bottom: 1px solid var(--sebrae-light-gray-2);
    display: flex;
    align-items: center;

    h4 {
      font: {
        family: var(--font-lato);
        weight: 700;
        size: 16px;

      }
    }
    .btn-without-style {
      position: absolute;
      right: 8px;
    }
  }
  &__body {
    position: relative;
    width: 100%;
    padding: 16px;

    p {
      font: {
        family: var(--font-lato);
        weight: 400;
        size: 13px;
      }
      line-height: 1.2;
      margin: 13px 0;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-top: 14px;
  }

  @media screen and (min-width: 768px) {
    &__header {
      h4 {
        font-size: 23px;
      }
    }
    &__body {
      p {
        font-size: 16px;
      }
    }
  }
}