.step-wrapper {
  border-radius: 4px;
  border: 1px solid #b6bfc8;
  background: var(--sebrae-white);

  display: flex;
  height: 32px;
  flex-shrink: 0;
  margin: 15px 0;

  button {
    display: block;
    width: 50%;
    height: 100%;

    &:first-child {
      border-right: 1px solid #b6bfc8;
    }

    &.active {
      background-color: #025eb8c7;
      color: var(--sebrae-white);
    }
  }
}
