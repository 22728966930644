.custom-progress-bar {
  position: relative;
  margin: 15px 0;

  @keyframes animate-stripes {
    100% {
      background-position: -100px 0;
    }
  }
  p {
    color: var(--sebrae-blue);
    font: {
      family: var(--font-lato);
      size: 13px;
      weight: 400;
    }
    line-height: 1;
    max-width: 100%;
  }
  progress[value] {
    appearance: none;
    border: none;
    width: 100%;
    height: 8px;
    background-color: #E2E6E8;
    border-radius: 4px;
    box-shadow: none;
    color: var(--sebrae-blue);
    position: relative;

    &::-webkit-progress-bar {
      background-color: #E2E6E8;
      border-radius: 4px;
      box-shadow: none;
    }
    &::-webkit-progress-value {
      position: relative;
      background-size: 100%;
      border-radius: 3px;
      background-image: -webkit-linear-gradient(45deg, #3b4aff 0%, #40bbff 100%);
      animation: animate-stripes 5s linear infinite;

      &:after {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        top: 7px;
        right: 7px;
        background-color: white;
        border-radius: 100%;
      }
    }
    &::-moz-progress-bar {
      background-image: -moz-linear-gradient(45deg, #3b4aff 0%, #40bbff 100%);
      background-size: 100%;
      border-radius: 4px;
    }
  }
  .progress-bar {
    display: flex;
    align-items: flex-end;
    gap: 9px;
    margin-bottom: 8px;

    &__bar {
      position: relative;
      width: 100%;
    }
    &__max {
      color: var(--sebrae-blue);
      font: {
        family: var(--font-lato);
        size: 16px;
        weight: 700;
      }
      line-height: 1;

      &.error {
        color: var(--sebrae-red);
      }
    }
    p {
      min-width: 18px;
      text-align: right;
      margin-bottom: -3px;
    }
  }

  &.error {
    p {
      color: var(--sebrae-red);
    }
    progress[value] {
      &::-webkit-progress-value {
        background-image: -webkit-linear-gradient(45deg, #F4455A 0%, #FFABAB 100%);
      }
      &::-moz-progress-bar {
        background-image: -moz-linear-gradient(45deg, #F4455A 0%, #FFABAB 100%);
      }
    }
  }
}