.wrapper-loading {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: transparent;

  &.full {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4px);
  }

  &.small {
    position: absolute;
    height: 20px;
    width: 20px;
    right: 40px;

    .loading {
      width: 20px;
      height: 20px;
      position: relative;

      div {
        position: absolute;
        width: 20px;
        height: 20px;
        border: 2px solid var(--sebrae-blue);
        border-top-color: transparent;
        top: 10px;
        left: 10px;
      }
    }
  }

  .loading {
    width: 70px;
    height: 70px;
    position: relative;

    div {
      position: absolute;
      width: 70px;
      height: 70px;
      border: 10px solid var(--sebrae-blue);
      border-top-color: transparent;
      border-radius: 50%;
      animation: loading 1s linear infinite;
      top: 36px;
      left: 36px;
    }
  }
}

@keyframes loading {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
