.modal-default {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;
  height: 100%;
  position: fixed;
  backdrop-filter: blur(4px);

  .close-page {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
  }

  .btn-close {
    top: 20px;
    right: 15px;
    position: absolute;
  }

  .wrapper-modal {
    bottom: 0;
    z-index: 2;
    width: 100%;
    padding: 16px;
    overflow: auto;
    max-height: 90vh;
    position: absolute;
    border-radius: 4px;
    border: 2px solid #CFE2FC;
    background-color: var(--sebrae-white);
    box-shadow: 0 1px 30px 6px rgba(20, 46, 82, 0.2);

    &.square {
      border-radius: 0;
    }
  }

  .modal-header {
    border-bottom: 1px solid #e0e5eb;
    margin: 0 -16px;
    padding: 0 16px;
    margin-bottom: 16px;
  }

  .title {
    color: #243342;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 23.3px;
    padding-bottom: 15px;
  }

  @media (min-width: 660px) {
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    .btn-close {
      display: block;
    }

    .wrapper-modal {
      width: 100%;
      height: auto;
      max-width: 540px;
      position: relative;
      padding: 20px 30px 32px;
      background-color: var(--sebrae-white);
      box-shadow: 0 1px 30px 0 rgba(20, 46, 82, 0.2);

      &.square {
        border-radius: 0;
      }
    }

    .modal-header {
      margin: 0 -30px;
      padding: 0 30px;
      margin-bottom: 16px;
    }
  }
}
