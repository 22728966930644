.accordion-section {
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 4px 0 rgba(20, 46, 82, 0.2);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;

  .accordion {
    background-color: #ffffff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    outline: none;
    transition: background-color 0.3s ease-in-out;

    .arrow {
      border: 1px solid #617385;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    figure {
      margin: 0;
    }

    &:hover,
    &.active {
      //background-color: #e6e6e6;
    }

    &-title {
      margin: 0;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      color: #617385;
    }

    &-icon {
      margin-left: auto;
      transition: transform 0.4s ease-in-out;
    }

    &-content {
      overflow: hidden;
      transition: max-height 0.4s ease-in-out;
      background-color: #ffffff;
      padding: 0 18px;
    }

    &-text {
      font-weight: 400;
      font-size: 14px;
      padding: 18px;
    }
  }
}

.rotate {
  transform: rotate(90deg);
}
