.acronyms {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--sebrae-white);
  font: {
    family: var(--font-lato);
    size: 16px;
    weight: 700;
  }
  text-transform: uppercase;
  box-shadow: 0 1px 4px 0 #142e5233;
  cursor: pointer;
}
