:root {
  --sebrae-black: #000000;
  --sebrae-blue: #005eb8;
  --sebrae-light-gray: #f5f7fa;
  --sebrae-light-gray-2: #E0E5EB;
  --sebrae-white: #ffffff;
  --sebrae-white-2: #f5f5f5;
  --sebrae-purple: #994599;
  --sebrae-light-blue: #5c8fd6;
  --sebrae-blue-2: #0f438a;
  --sebrae-blue-3: #0024a9;
  --sebrae-dark-blue: #142e52;
  --sebrae-dark-blue-2: #0a1729;
  --sebrae-cyan: #14adcc;
  --sebrae-blue-4: #0e778b;
  --sebrae-blue-5: #094f5d;
  --sebrae-gray: #243342;
  --sebrae-red: #bf303c;
  --font-alegreya: "Alegreya Sans";
  --font-lato: "Lato";
}

body {
  font: {
    family: Lato, "Alegreya Sans", "Open Sans", "Helvetica Neue", Helvetica,
    Arial, sans-serif !important;
    weight: 400;
  }
  background-color: var(--sebrae-white-2);

  * {
    box-sizing: border-box !important;
  }
}
main {
  min-height: 100vh;
  padding-top: 56px;

  @media screen and (min-width: 560px) {
    padding-top: 0;
    padding-left: 70px;
  }
}
img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-width: 100%;
}

.container {
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;
  max-width: 100%;
}
button,
a {
  cursor: pointer;
}
strong {
  font-weight: 700 !important;
}
.btn-without-style {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  outline: none;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.border-none {
  border: none !important;
}
.BaSe {
  &--helper-button-container {
    button {
      padding: 0 !important;
    }
  }
  &--input {
    margin-bottom: 15px;

    &-label,
    &-sub-label {
      color: var(--sebrae-gray) !important;
      font-weight: 400 !important;
    }
    &-sub-label {
      font: {
        size: 11px !important;
        style: normal !important;
      }
    }
    &-input {
      width: 100% !important;
    }
    &-wrapper-label {
      z-index: auto !important;
    }
    .BaSe--popover {
      .BaSe--small1 {
        white-space: pre-wrap;
      }
    }
  }
  &--popover-card {
    div:first-child {
      small {
        display: block;
        width: calc(100% - 10px);
        margin-bottom: 5px;
      }
    }
    div:last-child {
      color: var(--sebrae-gray);

      * {
        color: inherit!important;
      }
    }
  }
}
.wrapper-select {
  position: relative;
  width: 100%;

  .BaSe--select {
    margin-bottom: 15px;

    label {
      color: var(--sebrae-gray) !important;
      font-weight: 400 !important;

      &:last-of-type {
        font: {
          size: 11px !important;
          style: normal !important;
        }
      }
    }
    &-input {
      width: 100%;
    }
    ul {
      margin: 5px 0;

      p {
        margin: 0!important;
      }
    }
  }
}
.inputs-blue {
  .BaSe--input {
    color: var(--sebrae-blue);

    label, input {
      color: inherit!important;
    }
    input {
      border: 1px solid #8FB8EF!important;
      background: #CFE2FC!important;
    }
  }
}
.input-error-message {
  .error-message {
    display: none;
    margin-top: 5px;
    font: {
      size: 13px;
      weight: 400;
    }
  }
  &.invalid {
    color: var(--sebrae-red);

    label, input {
      color: var(--sebrae-red)!important;
    }
    input {
      background: #F3D8DA!important;
      border-color: var(--sebrae-red)!important;
    }
    .error-message {
      display: block;
    }
  }

}
.Toastify {
  &__toast {
    &-body {
      align-items: flex-start;
    }
    &-icon {
      width: 24px;
    }
    &--success {
      background: #ebfaf0;

      .toast-content h3 {
        color: #00662a !important;
      }
    }
    &--error {
      background: #ffeae5;

      .toast-content h3 {
        color: #b22400 !important;
      }
    }
  }

  .toast-content {
    h3 {
      color: #394d60;
      font: {
        family: var(--font-lato);
        size: 16px;
        weight: 700;
      }
      line-height: 1.2;
    }
    p {
      display: block;
      color: #394d60;
      font: {
        family: var(--font-lato);
        size: 13px;
        weight: 400;
      }
      margin: 2px 0;
      line-height: 1.2;
    }
  }
}
form {
  input, select {
    &[disabled] {
      cursor: not-allowed!important;
    }
  }
}
.react-datepicker__month--disabled {
  opacity: 0.65;
  cursor: not-allowed;
  box-shadow: none!important;
}