@mixin gradient-border($color1, $color2, $angle) {
  &::before {
    border-radius: 8px;
    content: "";
    background-image: linear-gradient($angle, $color1 0%, $color2 100%);
    top: -3.5px;
    left: -3.5px;
    bottom: -3.5px;
    right: -3.5px;
    position: absolute;
    z-index: -1;
  }
}

.company-pill-area {
  .blocks-area {
    gap: 16px;
    margin: 24px 0 48px;
    cursor: auto;
    display: flex;
    overflow-x: auto;
    user-select: none;

    &:active {
      cursor: grabbing;
    }

    .block {
      position: relative;
      height: 90px;
      padding: 0 13px;
      min-width: 139px;
      max-width: 139px;
      border-radius: 8px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: left;
      background-color: #fff;
      border: 1.5px solid black;
      border-radius: 8px;
      align-items: center;
      background: #fff;

      &.lucro {
        @include gradient-border(#40bbff, #3b4aff, 180deg);
      }

      &.lucratividade {
        @include gradient-border(#f4455a, #ffabab, 60deg);
      }

      &.rentabilidade {
        @include gradient-border(#ffb380, #ffed69, 60deg);
      }

      &.prazo {
        @include gradient-border(#9285f9, #cd5be8, 60deg);
      }

      &.necessidade {
        @include gradient-border(#3b4aff, #40bbff, 60deg);
      }

      p {
        text-align: left;
      }
    }
  }

  @media (max-width: 992px) {
    .blocks-area {
      height: 115px;
      padding-left: 2px;
      align-items: center;

      .block {
        &.lucro {
          &::before {
            top: -2.5px;
          }
        }
        &.lucratividade {
          &::before {
            top: -2.5px;
          }
        }
        &.rentabilidade {
          &::before {
            top: -2.5px;
          }
        }
        &.prazo {
          &::before {
            top: -2.5px;
          }
        }
        &.necessidade {
          &::before {
            top: -2.5px;
          }
        }
      }
    }
  }

  @media (max-width: 350px) {
    .blocks-area {
      .block {
        &.lucro {
          &::before {
            top: -3.5px;
          }
        }
        &.lucratividade {
          &::before {
            top: -3.5px;
          }
        }
        &.rentabilidade {
          &::before {
            top: -3.5px;
          }
        }
        &.prazo {
          &::before {
            top: -3.5px;
          }
        }
        &.necessidade {
          &::before {
            top: -3.5px;
          }
        }
      }
    }
  }

  @media (min-width: 993px) {
    .blocks-area {
      gap: 24px;
      width: 90%;
      padding: 5px;
      flex-wrap: wrap;

      .block {
        min-width: 183px;
        max-width: 183px;
        height: 104px;
        padding: 0 16px;
        border: 1px solid transparent;

        p {
          font-size: 19px;
        }
      }
    }
  }
}
