.related-companies-container {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100dvh - 56px);
  padding-top: 5%;

  @media (min-width: 768px) {
    padding-left: 5%;
  }

  .top-text {
    padding: 24px 0;

    @media (max-width: 768px) {
      padding: 10px 0;
    }

    @media (max-width: 350px) {
      padding: 0;
    }
  }

  .available-companies {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    margin: 15px 0;

    label {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    @media (min-width: 768px) {
      gap: 16px;

      label {
        justify-content: initial;
      }
    }

    @media (max-width: 816px) {
      gap: 8px;
    }
  }

  .company__register__area {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 32px;

    .BaSe--checkbox {
      div {
        &:last-child {
          width: 18px;
          height: 18px;
        }
      }
    }

    a {
      color: #394d60;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        filter: brightness(2);
      }
    }

    &__button {
      display: flex;
      justify-content: center;
    }

    &__motto {
      display: flex;
      align-items: center;
      width: 220px;
      padding: 24px 0;

      .line {
        flex-grow: 1;
        height: 1px;
        background-color: var(--sebrae-light-gray-2);
        margin: 0 10px;
      }
    }
  }

  .company__bottom__content {
    margin-top: auto;
    display: flex;
    flex-direction: column;

    .read-terms {
      display: flex;
      align-items: center;

      .BaSe--checkbox {
        div {
          &:last-child {
            width: 18px;
            height: 18px;
          }
        }
      }

      @media (max-width: 665px) {
        align-items: baseline;
      }

      @media (max-width: 320px) {
        padding-top: 16px;
      }

      .underlined-term {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          filter: brightness(2);
        }
      }
    }

    .company__button {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 32px 0 16px;

      &-continue {
        margin-left: auto;
      }

      @media (max-width: 350px) {
        padding-top: 16px;
      }

      @media (min-width: 768px) {
        flex-direction: row;
        padding: 32px 32px 0 0;

        &-continue {
          margin-left: 0;
        }
      }
    }
  }
}
