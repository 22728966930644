.menu-dropdown {
  position: relative;

  ul {
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 3;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 162px;
    height: auto;
    border-radius: 4px;
    background: var(--sebrae-white);
    border: 1px solid #E0E5EB;
    box-shadow: 0 1px 4px 0 rgba(20, 46, 82, 0.20);

    li {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 8px 12px;
    }
    .link {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}