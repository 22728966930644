.breadcrumb {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  .item {
    display: flex;
    align-items: center;
  }

  .separator {
    margin: 0 8px;
  }
}
