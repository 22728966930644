.onboarding {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: var(--sebrae-light-gray);
  color: var(--sebrae-black);

  &.type-page {
    position: relative;
    z-index: 1;
    height: 100vh;
  }

  .container {
    padding: 0 24px 90px;
    height: 100%;
    position: relative;
  }
  &__header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0 18px;

    .logo {
      width: 40px;
    }
  }
  &__skip {
    position: absolute;
    bottom: 0;
    right: 0;
    font: {
      size: 13px;
      weight: 400;
    }
  }
  &__footer {
    position: absolute;
    bottom: 25px;
    width: 100%;
    left: 0;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }
  h2 {
    font: {
      family: var(--font-lato);
      size: 19px;
      weight: 700;
    }
    line-height: 22.8px;
    margin-bottom: 16px;
  }
  p {
    font: {
      family: var(--font-lato);
      size: 16px;
      weight: 400;
    }
    line-height: 23.3px;
  }
  .step {
    figure {
      margin: 16px auto 20px;
      min-height: 214px;

      img {
        width: 300px;
        margin: 0 auto;
      }
    }
  }
  .pages-desktop,
  .navigate-desktop {
    display: none;
  }

  @media screen and (min-width: 375px) {
    .step {
      figure {
        margin-bottom: 37px;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .container {
      max-width: 1300px;
    }
    .steps {
      margin: 0 auto;
      width: 100%;
      max-width: 918px;
    }

    &__header {
      justify-content: flex-start;

      .logo {
        width: 56px;
      }
      .onboarding__skip {
        display: none;
      }
    }
    .step {
      position: relative;
      display: flex;
      justify-content: flex-end;
      gap: 127px;
      flex-direction: row-reverse;
      min-height: 339px;

      figure {
        margin: 0;
        min-height: 339px;
        img {
          width: auto;
        }
      }
      .text {
        position: relative;
        max-width: 390px;
      }
    }
    h2 {
      font-size: 23px;
      margin-bottom: 22px;
    }
    &__footer {
      display: none;
    }
    &__skip {
      position: relative;
    }
    .pages-desktop {
      padding: 35px 0 16px;
      display: block;
    }
    .navigate-desktop {
      position: absolute;
      width: 100%;
      bottom: 30px;
      padding-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
