.list-trash {
  margin: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--sebrae-white);
  min-height: 60px;
  position: relative;
  padding: 5px 10px;
  border-radius: 8px;
  margin: 5px -10px;

  &:last-child {
    border-bottom: 0;
  }

  &.restoring {
    background-color: #eceeef;
    opacity: 0.8;
    pointer-events: none;
  }

  .wrapper-loading {
    right: 90px;
  }

  button {
    text-decoration: underline;
    color: var(--sebrae-blue);
  }

  h5 {
    color: #243342;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 23.3px;
  }
  p {
    color: #617385;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    //padding-bottom: 10px;
  }
}
