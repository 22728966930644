.section-planning {
  .notes-modal {
    border-top: 1px solid #e0e5eb;
    padding-top: 24px;

    span {
      font-weight: bold;
    }
  }

  .box-title {
    h3 {
      color: var(--sebrae-blue);
      font-size: 27px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    h5 {
      padding: 20px 0;
      color: #394d60;
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .step {
    border-radius: 4px;
    border: 1px solid #b6bfc8;
    background: var(--sebrae-white);

    display: flex;
    height: 32px;
    flex-shrink: 0;
    margin: 15px 0;

    button {
      display: block;
      width: 50%;
      height: 100%;

      &:first-child {
        border-right: 1px solid #b6bfc8;
      }
      &:last-child {
        border-left: 1px solid #b6bfc8;
      }

      &.active {
        background-color: #025eb8c7;
        color: var(--sebrae-white);
      }
    }
  }

  .search {
    position: relative;

    input {
      width: 100% !important;
    }
    .icon {
      position: absolute;
      right: 10px;
      top: 8px;
      display: flex;
      align-items: center;
      height: calc(100% - 15px);

      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  .search-date {
    padding-bottom: 50px;
    position: relative;

    p {
      color: #617385;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-bottom: 10px;
    }

    .date {
      display: flex;
      gap: 15px;
      position: relative;
      z-index: 1;

      img {
        &:before {
          content: "";
          width: 1px;
          height: 100%;
          background-color: red;
        }
      }
    }

    .clear {
      text-align: right;
      padding: 10px 0;
      position: absolute;
      right: 0;
      bottom: 18px;

      button {
        color: #006bff;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
      }
    }
  }

  .more-planning {
    text-align: center;
    margin-bottom: 30px;

    button {
      color: var(--sebrae-blue);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }
  }

  .list-planing {
    .empty {
      display: flex;
      gap: 15px;
    }
  }

  @media (min-width: 560px) {
    .wrapper-search {
      max-width: 500px;

      .date {
        & > div {
          width: 100%;
        }
      }
    }
  }
}
