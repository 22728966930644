.custom-textarea {
  position: relative;
  width: 100%;
  margin: 15px 0;

  .input-textarea {
    width: 100%;
    color: var(--sebrae-gray);
    font: {
      family: var(--font-lato);
      weight: 400;
    }

    label {
      display: block;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 2px;
    }
    textarea {
      display: block;
      width: 100%;
      height: 116px;
      color: var(--sebrae-gray);
      font: {
        size: 16px;
        weight: 400!important;
      }
      line-height: 16px;
      resize: none;
      background: var(--sebrae-white);
      border-radius: 4px;
      border: 1px solid rgb(182, 191, 200);
      padding: 8px;

      &::placeholder {
        color: #8b99a7;
      }
    }
  }
}