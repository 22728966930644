.title-with-popover {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 8px;

  .small-title {
    color: inherit;
    font: {
      size: inherit!important;
      weight: 400!important;
    }
  }

  .BaSe--popover-card div:last-of-type {
    .BaSe--small1 {
      font-size: 11px!important;
      color: var(--sebrae-gray);
    }
  }
  .BaSe--popover {
    .BaSe--small1 {
      white-space: pre-wrap;
    }
  }
}