.card-status {
  gap: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(20, 46, 82, 0.2);
  min-height: 70px;
  padding: 10px 15px;
  position: relative;
  margin-bottom: 15px;
  background-color: var(--sebrae-white);
  display: flex;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &:before {
    content: "";
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #9285f9;
    border-radius: 8px 0 0 8px;
  }

  &.completed {
    &:before {
      background: #84f4bc;
    }
  }

  &.disabled {
    opacity: 0.7;
    cursor: no-drop;
    pointer-events: none;
  }

  h5 {
    color: #243342;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 23.3px;
  }

  p {
    color: #617385;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .open-options {
    border-radius: 50%;
    padding: 3px;
    transition: 0.3s;

    &:hover {
      background-color: #f1f1f1;
    }
  }

  .options {
    position: absolute;
    right: 8px;
    top: 56px;
    background: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(20, 46, 82, 0.2);
    padding: 15px;
    z-index: 1;
    transition: 0.3s;
    display: none;
    align-items: center;

    &:before {
      content: "";
      position: absolute;
      top: -18px;
      right: 9px;
      border-bottom: 10px solid #ffffff;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
    }

    &.open {
      display: flex;
    }

    ul {
      li {
        padding-bottom: 15px;
        .link {
          display: flex;
          align-items: center;
          gap: 12px;
        }

        &:last-child {
          padding-bottom: 0;
        }

        a {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }
}
