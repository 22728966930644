.container {
  .all-pills-container {
    height: calc(100vh - 56px);
    display: flex;
    max-width: 1120px;
    flex-direction: column;
    justify-content: space-between;

    .back-page {
      display: flex;
      margin: 35px 0;
      cursor: pointer;
      text-decoration: none;
    }

    &__desktop-title {
      display: none;
    }

    .all-pill-cards {
      gap: 24px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      .media-area {
        cursor: pointer;

        .video {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 100%;
          padding: 16px;
          max-width: 288px;
          min-height: 207px;
          border-radius: 4.91px 24.54px 24.54px 24.54px;
          background-color: #fff;
          box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.05);
        }

        .image {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 100%;
          max-width: 357px;
          min-height: 184px;
        }
      }
    }

    &__pagination {
      padding: 24px 0;
    }
  }

  @media (min-width: 768px) {
    .all-pills-container {
      margin-left: 0;

      .all-pill-cards {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .media-area {
          .video {
            min-height: 230px;
            max-width: 357px;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .all-pills-container {
      margin-left: 3%;

      &__mobile {
        display: none;
      }

      &__desktop-title {
        display: block;
        margin: 35px 0;
      }

      .all-pill-cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .video,
        .image {
          min-width: 286px;
        }
      }

      &__pagination {
        padding: 0;
        padding-top: 16px;
        padding-bottom: 5%;
      }
    }
  }
}
