.custom-input-range {
  position: relative;
  width: 100%;
  margin: 15px 0;
  border-radius: 12px;
  box-shadow: 0 1px 4px 0 rgba(20, 46, 82, 0.20);
  padding: 1px;
  background: var(--sebrae-white);
  transition: all .3s ease-in-out;

  .input-range {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 16px;
    border-radius: 12px;

    background: var(--sebrae-white);
    color: #617385;

    label {
      font: {
        family: var(--font-lato);
        size: 16px;
        weight: 700;
      }
      line-height: 23px;
      margin-bottom: 15px;
    }
    input {
      width: 100%;
      -webkit-appearance: none;
      appearance: none;
      outline: none;
      margin: 0;
      outline: 0;
      height: 3px;
      border-radius: 10px;
      background: var(--sebrae-light-gray-2);
      outline: none;
      cursor: pointer;

      &::-webkit-slider-runnable-track {
        display: flex;
        align-items: center;
        position: relative;
        height: 3px;
        border-radius: 10px;
        background: var(--sebrae-light-gray-2);
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: none;
        background-color: var(--sebrae-blue);
        transform: translateY(-6px);
        cursor: inherit;
      }
      &::-moz-range-thumb {
        appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: var(--sebrae-blue);
        cursor: inherit;
        border: none;
      }
    }
    &__wrapper-input {
      position: relative;
      width: 100%;
      padding: 25px 0;
      display: flex;
      align-items: center;

      small {
        font: {
          family: var(--font-lato);
          size: 11px;
          style: italic;
          weight: 400;
        }
        line-height: 1;
        color: #617385;
        position: absolute;
        bottom: 0;

        &:first-of-type {
          left: 0;
        }
        &:last-of-type {
          right: 0;
        }
      }
      .label-value {
        position: absolute;
        left: 0;
        z-index: 1;
        top: 0;
        font: {
          family: var(--font-lato);
          size: 13px;
          weight: 400;
        }
        line-height: 1;
        display: flex;
        align-items: center;
      }
    }
  }
  &.has-value {
    background: linear-gradient(to right, #3b4aff 0%,#40bbff 100%);

    .input-range {
      color: var(--sebrae-blue);
    }
  }
}