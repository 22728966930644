.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    display: flex;
    gap: 10px;
    padding: 0 16px;
  }
}
