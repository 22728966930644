.pill-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    height: 145px;
    background-image: linear-gradient(5deg, #9285f9 0%, #3b4aff 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .back-page {
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-left: -6px;
    }
  }

  .previousAndNextPage {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 24px 6px 30px;

    a {
      display: flex;
      align-items: center;
    }

    .pill-disabled {
      text-decoration: none;
      cursor: no-drop;
    }
  }

  .text-container {
    p {
      white-space: pre-line;
    }
  }

  .image-container {
    width: 100%;
    float: left;
    padding: 30px 0;

    img {
      width: 100%;
    }
  }

  .media-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: 210px;
    padding: 14px;
    margin: 24px 0;
    border-radius: 4.91px 24.54px 24.54px 24.54px;
    background-color: #fff;
    box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.05);

    iframe {
      width: 100%;
      height: 100%;
      border-radius: 4.91px 24.54px 24.54px 24.54px;
    }
  }

  @media (max-width: 768px) {
    &__header {
      height: 176px;
      background-image: linear-gradient(25deg, #9285f9 0%, #cd5be8 100%);
      padding: 0 16px;
      margin: 0 -16px;

      .breadcrump {
        display: none;
      }
    }

    &__body-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      padding-top: 16px;

      .text-container {
        text-align: left;
      }
    }
  }

  @media (min-width: 769px) {
    &__header {
      border-radius: 0 0 8px 8px;
      justify-content: space-evenly;
      padding: 0 16px;

      .back-page {
        display: none;
      }
    }

    .media-container {
      max-width: 50%;
      height: 380px;
      margin: 30px 30px 30px 0;
    }

    .text-container {
      padding-top: 30px;
    }

    .image-container {
      width: 50%;
      padding: 30px 30px 30px 0;
    }
  }

  @media (min-width: 1440px) {
    width: 100%;
    max-width: 76.389vw;
  }
}
